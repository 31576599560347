<template>
	<div id="sales_dep">
		<div class="main-body-filter">
			<div class="main-body-filter-left">
				<el-select v-model="filter.bigarea.value" :loading="filter.bigarea.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部区域" @change="selectFilter">
					<el-option v-for="item in filter.bigarea.options" :key="item.bg_id" :label="item.bg_areaname"
						:value="item.bg_id">
					</el-option>
				</el-select>
				<el-input class="search-keywords filter-input" size="large" placeholder="请输入售楼部名称检索查询"
					prefix-icon="Search" v-model="filter.keywords" clearable @keyup.enter="inputFilter">
				</el-input>
			</div>
			<div class="main-body-filter-right">
				<el-button type="primary" icon="Plus" @click="openAddSalesDepPage">
					添加售楼部
				</el-button>
			</div>
		</div>
		<div class="main-body-table">
			<SelectFilePrint :datas="table.columns"  @selectClick="selectClick"></SelectFilePrint>
			<SelectTable id="printBill" :tables="table.list" :confs="table.conf" :filters="table.columns" :icons="['Edit', 'DeleteFilled']" :operate="true" @sizeChange="tablePageSize" @currentChange="tableChosePage" @iconClick="iconClick"></SelectTable>
		</div>
		<div class="dialog-page">
			<AddSalesDep ref="add_sales_dep_ref" @submit_suc='loadList'></AddSalesDep>
		</div>
	</div>
</template>

<script setup>
	import tableColumns from '@/assets/json/face/table/sales-dep.json'
	import AddSalesDep from '@/components/carema/AddSalesDep.vue'
	import CusTable from '@/components/common/CusTable.vue'
	import SelectFilePrint from '@/components/common/SelectFilePrint.vue'
	import SelectTable from '@/components/common/SelectTable.vue'
	import { exportToExcel } from "@/utils/out_excel.js"; // 导出表格excel
	import { exportToCsv } from "@/utils/out_csv.js"; // 导出表格csv
	import { exportToPrint } from "@/utils/out_print.js"; // 打印
	import {
		getCurrentInstance,
		ref,
		onMounted,
		reactive
	} from 'vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 顶部筛选
	const filter = reactive({
		keywords: '',
		bigarea: {
			options: [],
			value: '',
			is_load: false
		}
	});

	// 列表信息
	const table = reactive({
		list: [],
		columns: tableColumns.list,
		conf: {
			loadStatus: false,
			curPage: 1,
			pageLimit: 15,
			sizes: [15, 50, 500, 3000],
			dataTotal: 0,
			emptyText: ''
		},
	})

	// 获取table列表
	const loadList = () => {
		// 清除现有table数据
		table.list = []
		table.conf.loadStatus = true
		table.conf.emptyText = '数据查询中...'
		ctx.$request_nl_.post(ctx.$api_.state.Face.sales_dep.list.url, {
				bg_id: filter.bigarea.value,
				keywords: filter.keywords,
				page: table.conf.curPage,
				limit: table.conf.pageLimit
			})
			.then((respon) => {
				if (respon.Code === ctx.$code_.state.success) {
					table.conf.dataTotal = respon.Data.total
					table.list = respon.Data.list
				} else {
					ctx.$message.error({
						message: respon.Message
					});
				}

				table.conf.loadStatus = false
				table.conf.emptyText = respon.Data.list.length > 0 ? respon.Message : "暂无更多数据"
			}).catch(error => {
				table.conf.loadStatus = false
				table.conf.emptyText = "服务器连接失败，请稍后重试"
			})
	}

	// 筛选框调用事件
	const selectFilter = () => {
		filter.keywords = ''
		table.conf.curPage = 1

		// 重新加载table数据
		loadList()
	}

	// 输入框调用事件
	const inputFilter = () => {
		// 清空select选项框
		filter.bigarea.value = ''
		table.conf.curPage = 1

		// 重新加载table数据
		loadList()
	}

	// 设置table当前页码变化
	const tableChosePage = (curPage) => {
		// 存放当前页面
		table.conf.curPage = curPage

		// 重新加载table数据
		loadList()
	}

	// 设置table每页页码数
	const tablePageSize = (limit, page) => {
		// 存放页码数
		table.conf.pageLimit = limit

		// 判断查询当前页  *  条数 > 总条数不进行查询
		if (page === 1 || limit * page <= table.conf.dataTotal) {
			// 重新加载table数据
			loadList()
		}
	}

	// 添加售楼部
	const openAddSalesDepPage = () => {
		add_sales_dep_ref.value.openPage()
	}

	// 导出和打印
	const selectClick = (type, val) => {
		if(type==="excel") {
			let headArr = []
			for (let index = 0; index < table.columns.length; index++) {
				const element = table.columns[index];
				if(element.show) {
					headArr.push(element.label)
				}
			}
			let smallArr = []
			let tableArr = []
			for (let index = 0; index < table.list.length; index++) {
				smallArr = []
				let element = table.list[index]
				for (let i = 0; i < table.columns.length; i++) {
					if(table.columns[i].show) {
						let e = table.columns[i].prop
						smallArr.push(element[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToExcel(tableArr, headArr, "sheet1", '售楼部');
		} else if(type==="csv") {
			let headObj = {}
			for (let index = 0; index < table.columns.length; index++) {
				const element = table.columns[index];
				if(element.show) {
					headObj[element.label] = element.label
				}
			}
			let smallArr = {}
			let tableArr = []
			for (let index = 0; index < table.list.length; index++) {
				smallArr = {}
				let element = table.list[index]
				for (let i = 0; i < table.columns.length; i++) {
					if(table.columns[i].show) {
						let e = table.columns[i].prop
						smallArr[e] = (element[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToCsv(tableArr, headObj, '售楼部')
		} else if(type==="print") {
			exportToPrint('printBill')
		}
	}

	// 操作点击
	const iconClick = (val, item) => {
		let i = table.list.findIndex((value,index,array)=>{
			// 找不到的就返回-1
			return  value.sd_id === item.sd_id;
		})
		switch(val){
			case 'DeleteFilled':
				// 删除
				DelSalesDep(i)
				break;
			case 'Edit':
				// 编辑
				EditSalesDep(i)
				break;
			default:
				break;
		}
	}

	// 编辑售楼部
	const EditSalesDep = (index) => {
		add_sales_dep_ref.value.editPage(table.list[index])
	}

	// 删除售楼部
	const DelSalesDep = (index) => {
		ElMessageBox.confirm(
				'您确定要删除此数据?',
				'温馨提示', {
					confirmButtonText: 'OK',
					cancelButtonText: '取消',
					type: 'warning',
				}
			)
			.then(() => {
				ctx.$request_nl_.post(ctx.$api_.state.Face.sales_dep.del.url, {
						sd_id: table.list[index].sd_id,
					})
					.then((respon) => {
						if (respon.Code === ctx.$code_.state.success) {
							ctx.$message.success({
								message: '删除成功'
							});
							table.list.splice(index,1)
						} else {
							ctx.$message.error({
								message: res.Message
							});
						}
						
					}).catch(error => {
					
					})

			})

	}
	// 挂载
	const table_ref = ref(null)
	const add_sales_dep_ref = ref(null)

	onMounted(() => {
		// 初始化加载区域列表
		ctx.$pubFun_.getSelectCommonBigarea(filter.bigarea)

		// 初始化加载表格列表
		loadList()
	})
</script>