<template>
	<div id="add_sales_dep">
		<el-dialog :title="saleTitle" v-model="pageConf.show" destroy-on-close :width="600">
			<el-form ref="formRef" class="common-dialog-form" :model="forms" :rules="rules" size="large">
				<el-form-item label="售楼部名称" prop="sd_name">
					<el-input v-model="forms.sd_name" maxlength="30" placeholder="请输入售楼部名称">
					</el-input>
				</el-form-item>
				<el-form-item label="通信密钥" prop="sd_app_key">
					<el-input v-model="forms.sd_app_key" maxlength="50"
						placeholder="请输入通信密钥"></el-input>
				</el-form-item>
				<el-form-item label="所属区域" prop="sd_bgid">
					<el-input v-if="hasEdit" v-model="echoData.bg_name" maxlength="50" :disabled="hasEdit"
						placeholder="售楼部所属区域"></el-input>
					<el-select v-else v-model="forms.sd_bgid" filterable placeholder="请选择售楼部所属区域"
						:loading="bigarea.is_load" @change="loadGroupList">
						<el-option v-for="item in bigarea.options" :key="item.bg_id" :label="item.bg_areaname"
							:value="item.bg_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设备分组" prop="sd_grid">
					<el-input v-if="hasEdit" v-model="echoData.gr_name" maxlength="50" :disabled="hasEdit"
						placeholder="售楼部绑定设备分组"></el-input>
					<el-select v-else v-model="forms.sd_grid" filterable placeholder="请选择售楼部绑定设备分组"
						:loading="group.is_load">
						<el-option v-for=" item in group.options" :key="item.gr_id" :label="item.gr_name"
							:value="item.gr_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="集合阈值" prop="sd_faceset_limit">
					<el-input type="number" v-model="forms.sd_faceset_limit" maxlength="10" placeholder="请输入集合阈值">
					</el-input>
				</el-form-item>
				<el-form-item label="负责人名称" prop="sd_manager_name">
					<el-input v-model="forms.sd_manager_name" placeholder="请输入售楼部负责人名称"></el-input>
				</el-form-item>
				<el-form-item label="负责人UserId" prop="sd_manager_userid">
					<el-input v-model="forms.sd_manager_userid" placeholder="请输入售楼部负责人userId"></el-input>
				</el-form-item>
				<el-form-item label="售楼部排序" prop="sd_sort">
					<el-input type="number" v-model="forms.sd_sort" maxlength="5" placeholder="请输入售楼部排序"></el-input>
				</el-form-item>
				<el-form-item label="备注说明">
					<el-input type="textarea" v-model="forms.sd_remark" maxlength="200" placeholder="备注说明，不超过200个字符">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="common-custom-forms-btn" @click="submitForm()">{{hasEdit?'立即修改':'立即添加'}}
					</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		getCurrentInstance,
		ref,
		reactive,
		defineExpose,
		defineEmits
	} from 'vue'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 弹出层配置文件
	const pageConf = reactive({
		show: false
	})
	const saleTitle = ref('')
	// 显示页面
	const openPage = () => {
		saleTitle.value = '添加售楼部'
		hasEdit.value = false
		// 重置表单信息
		forms.sd_name = ''
		forms.sd_app_key = ''
		forms.sd_bgid = ''
		forms.sd_grid = ''
		forms.sd_faceset_limit = ''
		forms.sd_manager_name = ''
		forms.sd_manager_userid = ''
		forms.sd_sort = ''
		forms.sd_remark = ''
		group.options = []

		pageConf.show = true

		if (bigarea.options.length <= 0) {
			// 初始化加载区域列表
			ctx.$pubFun_.getSelectCommonBigarea(bigarea)
		}
	}
	//编辑
	const hasEdit = ref(false)
	const echoData = reactive({
		bg_name: '',
		gr_name: ''
	})
	const editPage = async (data) => {
		forms['sd_id'] = data.sd_id
		saleTitle.value = '编辑售楼部'
		hasEdit.value = true
		// 重置表单信息
		forms.sd_name = data.sd_name
		forms.sd_app_key = data.sd_app_key
		forms.sd_faceset_limit = data.sd_faceset_limit
		forms.sd_manager_name = data.sd_manager_name
		forms.sd_manager_userid = data.sd_manager_userid
		forms.sd_sort = data.sd_sort
		forms.sd_remark = data.sd_remark
		group.options = []
		echoData.bg_name = data.bg_name
		echoData.gr_name = data.gr_name
		pageConf.show = true
		if (bigarea.options.length <= 0) {
			// 初始化加载区域列表
			await ctx.$pubFun_.getSelectCommonBigarea(bigarea)
			forms.sd_bgid = data.sd_bgid
		}
		if (forms.sd_bgid) {
			await loadGroupList(forms.sd_bgid)
			forms.sd_grid = data.sd_grid
		}
	}

	// 设置待抛出的方法名称
	const emit = defineEmits(['submit_suc'])

	// 公共区域列表
	const bigarea = reactive({
		options: [],
		is_load: false
	})

	// 公共设备分组列表
	const group = reactive({
		options: [],
		is_load: false
	})

	// 表单数据
	const forms = reactive({
		sd_name: '',
		sd_app_key: '',
		sd_bgid: '',
		sd_grid: '',
		sd_faceset_limit: '',
		sd_manager_name: '',
		sd_manager_userid: '',
		sd_sort: '',
		sd_remark: ''
	})

	// 设置表单ref
	const formRef = ref()

	// 设置表单验证规则
	const rules = reactive({
		sd_name: [{
			required: true,
			message: '请输入售楼部名称',
			trigger: 'blur'
		}],
		sd_app_key: [{
			required: true,
			message: '请输入通信密钥',
			trigger: 'blur'
		}],
		sd_bgid: [{
			required: true,
			message: '请选择所属区域',
			trigger: 'change'
		}],
		sd_grid: [{
			required: true,
			message: '请选择设备分组',
			trigger: 'change'
		}],
		sd_faceset_limit: [{
			required: true,
			message: '请输入集合阈值',
			trigger: 'blur'
		}],
		sd_sort: [{
			required: true,
			message: '请输入售楼部排序',
			trigger: 'blur'
		}]
	})

	// 区域选中，更改设备分组列表数据
	const loadGroupList = (bg_id) => {
		group.options = []
		group.is_load = true
		forms.sd_grid = ''
		ctx.$request_nl_.post(ctx.$api_.state.Frame.BaseInfo.get_common_not_bind_group.url, {
				bg_id: bg_id
			})
			.then((respon) => {
				if (respon.Code === ctx.$code_.state.success) {
					group.options = respon.Data
				} else {
					ctx.$notify.warning({
						title: '温馨提示',
						message: respon.Message
					})

					group.options = []
				}

				group.is_load = false
			}).catch(error => {
				group.is_load = false
			})
	}

	// 提交表单数据
	const submitForm = () => {
		formRef.value.validate((valid) => {
			if (valid === true) {
				
				ctx.$request_.post(hasEdit.value ? ctx.$api_.state.Face.sales_dep
					.edit.url : ctx.$api_.state.Face.sales_dep
					.add.url, forms).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})
						pageConf.show = false
						emit('submit_suc')
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}
		})
	}

	defineExpose({
		openPage,
		editPage
	})
</script>
<style lang="less">
	#add_sales_dep {
		.el-dialog {
			margin-top: 15vh !important;

			.el-dialog__body {
				padding: 0 1.1vw;
				height: 70vh;
			}
		}
	}
</style>